<template>
  <div class="van-main register-page page">
    <van-form class="login-form signature">
      <div class="van-common-detail">
        <p class="van-signature-title">
          企业用户服务协议
        </p>
      </div>
      <van-button block type="info" native-type="button" @click="submit">
        我已阅读并同意
      </van-button>
    </van-form>
    <p class="examine-title">
      {{ constants.examineTitle }}
    </p>
  </div>
</template>

<script>
import { Field, Form, Button, NoticeBar } from 'vant'
export default {
  components: {
    [Field.name]: Field,
    [Form.name]: Form,
    [Button.name]: Button,
    [NoticeBar.name]: NoticeBar
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    // 跳转人脸识别
    submit () {
      // 跳转共同人脸识别
      const data = {
        identityCard: this._.get(this.$store.state, 'employeeInfo.sn', ''),
        name: this._.get(this.$store.state, 'employeeInfo.realName', ''),
        redirectUrl: window.location.protocol + '//' + window.location.host + '/signature/signSuccess'
      }
      this.utils.faceValidate(data)
    }
  }
}
</script>

<style>

</style>
