var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main register-page page" },
    [
      _c(
        "van-form",
        { staticClass: "login-form signature" },
        [
          _c("div", { staticClass: "van-common-detail" }, [
            _c("p", { staticClass: "van-signature-title" }, [
              _vm._v(" 企业用户服务协议 ")
            ])
          ]),
          _c(
            "van-button",
            {
              attrs: { block: "", type: "info", "native-type": "button" },
              on: { click: _vm.submit }
            },
            [_vm._v(" 我已阅读并同意 ")]
          )
        ],
        1
      ),
      _c("p", { staticClass: "examine-title" }, [
        _vm._v(" " + _vm._s(_vm.constants.examineTitle) + " ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }